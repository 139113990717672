/* import __COLOCATED_TEMPLATE__ from './navbar.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { NavItem, type NavItemInputs, NavTree } from 'embercom/objects/standalone/nav';
import type IntlService from 'ember-intl/services/intl';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';
import type RouterService from '@ember/routing/router-service';

const ONBOARDING_WIDGET_MIN_HEIGHT_PX = '76px';
const ONBOARDING_WIDGET_STEPS_COMPLETED_HEIGHT_PX = '116px';

interface Args {}

export default class StandaloneNavbar extends Component<Args> {
  @service declare appService: {
    app: {
      canUseNewZendeskMessengerExperience: boolean;
      canDisplayExpiredSubscriptionPage: boolean;
      id: string;
    };
  };
  @service declare intl: IntlService;
  @service declare greatGuidanceService: GreatGuidanceService;
  @service declare router: RouterService;

  get showRestrictedNavBar() {
    return this.app.canDisplayExpiredSubscriptionPage;
  }

  get app() {
    return this.appService.app;
  }

  @tracked links: NavItem[] = [];

  settingsLink: NavItem;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let tree = new NavTree(owner);

    let navItems = [
      {
        route: 'apps.app.standalone.reports',
        icon: 'column-chart',
        labelIntlKey: 'standalone.navbar.report',
        children: [
          {
            route: 'apps.app.standalone.reports',
            labelIntlKey: 'standalone.navbar.performance',
          },
          {
            route: 'apps.app.standalone.content-gaps',
            labelIntlKey: 'standalone.navbar.content-gaps',
          },
          {
            route: 'inbox.workspace.inbox.inbox',
            labelIntlKey: 'standalone.navbar.conversations',
            // eslint-disable-next-line object-shorthand
            customOnClick: function () {
              let self = this as NavItem;
              self.router.transitionTo(self.route, self.appService.app.id, 'shared', 'all');
            },
            activeRoutes: ['inbox.workspace'],
          },
        ],
      },
      {
        route: 'apps.app.knowledge-hub.overview',
        icon: 'article',
        labelIntlKey: 'standalone.navbar.train',
        children: [
          {
            route: 'apps.app.knowledge-hub.overview',
            labelIntlKey: 'standalone.navbar.sources',
            requiredFeatures: ['team-standalone-copilot'],
          },
          {
            route: 'apps.app.knowledge-hub.overview',
            labelIntlKey: 'standalone.navbar.content',
            activeRoutes: [
              'apps.app.knowledge-hub.all-content',
              'apps.app.knowledge-hub.edit',
              'apps.app.knowledge-hub.folder',
              'apps.app.knowledge-hub.loading',
              'apps.app.knowledge-hub.new',
              'apps.app.knowledge-hub.view',
              'apps.app.knowledge-hub.overview',
            ],
            excludedFeatures: ['team-standalone-copilot'],
          },
          {
            route: 'apps.app.knowledge-hub.all-content',
            labelIntlKey: 'standalone.navbar.content',
            activeRoutes: [
              'apps.app.knowledge-hub.all-content',
              'apps.app.knowledge-hub.edit',
              'apps.app.knowledge-hub.folder',
              'apps.app.knowledge-hub.loading',
              'apps.app.knowledge-hub.new',
              'apps.app.knowledge-hub.view',
            ],
            requiredFeatures: ['team-standalone-copilot'],
          },

          {
            route: 'apps.app.standalone.knowledge.custom-answers',
            labelIntlKey: 'standalone.navbar.custom-answers',
            requiredFeatures: ['team-standalone-custom-answers'],
          },
          {
            route: 'apps.app.standalone.guidance',
            labelIntlKey: 'standalone.navbar.guidance',
            requiredFeatures: ['fin-guidance-closed-beta', 'team-standalone-fin-guidance'],
          },
          {
            route: 'apps.app.standalone.tasks',
            labelIntlKey: 'standalone.navbar.processes',
            requiredFeatures: ['answerbot-fin-actions-workflow-write-actions'],
          },
          {
            route: 'apps.app.standalone.knowledge.suggestions',
            labelIntlKey: 'standalone.navbar.suggestions',
            requiredFeatures: ['conversation-extraction-external-sources'],
          },
        ],
      },
      {
        route: 'apps.app.standalone.testing',
        icon: 'test',
        labelIntlKey: 'standalone.navbar.test',
        requiredFeatures: ['answerbot-fin-playground'],
      },
      {
        route: 'apps.app.standalone.channels',
        icon: 'rocket-ship',
        labelIntlKey: 'standalone.navbar.deploy',
        // eslint-disable-next-line object-shorthand
        customOnClick: function () {
          let self = this as NavItem;
          self.root.setForcedActiveItem(self);
          self.router.transitionTo(`${self.route}.overview`);
        },
        activeRoutes: ['apps.app.standalone.deploy.overview'],
        children: [
          {
            route: 'apps.app.standalone.deploy.intercom-messenger',
            labelIntlKey: 'standalone.navbar.intercom-messenger',
            activeRoutes: ['apps.app.standalone.deploy.intercom-messenger.workflow'],
          },
          {
            route: 'apps.app.standalone.deploy.salesforce.cases',
            labelIntlKey: 'standalone.navbar.salesforce-cases',
            platforms: ['salesforce'],
          },
          {
            route: 'apps.app.standalone.deploy.zendesk.tickets',
            labelIntlKey: 'standalone.navbar.zendesk-tickets',
            platforms: ['zendesk'],
            activeRoutes: ['apps.app.standalone.deploy.zendesk.tickets.workflow'],
          },
          {
            route: 'apps.app.standalone.deploy.zendesk.messaging',
            labelIntlKey: 'standalone.navbar.zendesk-messaging',
            platforms: ['zendesk'],
            activeRoutes: ['apps.app.standalone.deploy.zendesk.messaging.workflow'],
          },
        ],
      },
    ] as NavItemInputs[];

    this.links = this.showRestrictedNavBar
      ? []
      : navItems.map((item) => new NavItem(tree, owner, item));

    this.settingsLink = new NavItem(tree, owner, {
      route: 'apps.app.settings',
      icon: 'settings',
      labelIntlKey: 'standalone.navbar.settings',
    });
  }

  get isOnGettingStartedRoute() {
    return this.router.currentRouteName.startsWith('apps.app.getting-started');
  }

  get onboardingSteps(): any[] {
    return this.greatGuidanceService.steps;
  }

  get totalOnboardingSteps() {
    return this.onboardingSteps.length;
  }

  get completedOnboardingSteps() {
    return this.onboardingSteps.filter((step: any) => step.completed).length;
  }

  get allOnboardingStepsCompleted() {
    return this.completedOnboardingSteps === this.totalOnboardingSteps;
  }

  get onboardingWidgetHeight() {
    if (!this.greatGuidanceService.hideFloatingWidget && !this.isOnGettingStartedRoute) {
      if (this.allOnboardingStepsCompleted) {
        return ONBOARDING_WIDGET_STEPS_COMPLETED_HEIGHT_PX;
      }
      let onboardingWidgetHeight = document.getElementById('onboarding-floating-widget-container');
      return onboardingWidgetHeight
        ? `${onboardingWidgetHeight.clientHeight}px`
        : ONBOARDING_WIDGET_MIN_HEIGHT_PX;
    }
    return '0px';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Navbar': typeof StandaloneNavbar;
    'standalone::navbar': typeof StandaloneNavbar;
  }
}
